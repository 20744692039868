<template>
    <b-sidebar
        id="add-new-user-sidebar"
        :visible="isAddNewUserSidebarActive"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        right
        @hidden="resetForm"
        @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
    >
        <template #default="{ hide }">
            <!-- Header -->
            <div
                class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
            >
                <h5 class="mb-0">Registrar nuevo usuario</h5>

                <feather-icon
                    class="ml-1 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="hide"
                />
            </div>

            <!-- BODY -->
            <validation-observer
                #default="{ handleSubmit }"
                ref="refFormObserver"
            >
                <!-- Form -->
                <b-form
                    class="p-2"
                    @submit.prevent="handleSubmit(onSubmit)"
                    @reset.prevent="resetForm"
                >
                    <!-- Full Name -->
                    <validation-provider
                        #default="validationContext"
                        name="Nombre"
                        rules="required"
                    >
                        <b-form-group label="Nombre" label-for="full-name">
                            <b-form-input
                                id="full-name"
                                v-model="userData.name"
                                autofocus
                                :state="getValidationState(validationContext)"
                                trim
                                placeholder="Juan Perez"
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Email -->
                    <validation-provider
                        #default="validationContext"
                        name="Correo Electrónico"
                        rules="required|email"
                    >
                        <b-form-group
                            label="Correo Electrónico"
                            label-for="email"
                        >
                            <b-form-input
                                id="email"
                                v-model="userData.email"
                                placeholder="juanperez@correo.com"
                                :state="getValidationState(validationContext)"
                                trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Password -->
                    <validation-provider
                        #default="validationContext"
                        name="Contraseña"
                        rules="required"
                    >
                        <b-form-group label="Contraseña" label-for="password">
                            <b-form-input
                                id="password"
                                v-model="userData.password"
                                type="password"
                                :state="getValidationState(validationContext)"
                                trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Password -->
                    <validation-provider
                        #default="validationContext"
                        name="Confirmar Contraseña"
                        rules="required"
                    >
                        <b-form-group
                            label="Confirmar Contraseña"
                            label-for="c_password"
                        >
                            <b-form-input
                                id="c_password"
                                v-model="userData.c_password"
                                type="password"
                                :state="getValidationState(validationContext)"
                                trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <b-form-group label="Asignar Roles" label-for="roles">
                        <b-form-select
                            multiple
                            id="centro_costo_padre_id"
                            v-model="userData.roles"
                            :options="roles"
                            trim
                        >
                        </b-form-select>
                    </b-form-group>

                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-2"
                            type="submit"
                        >
                            Registrar
                        </b-button>
                        <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="button"
                            variant="outline-secondary"
                            @click="hide"
                        >
                            Cancelar
                        </b-button>
                    </div>
                </b-form>
            </validation-observer>
        </template>
    </b-sidebar>
</template>

<script>
import {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormSelect,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import countries from "@/@fake-db/data/other/countries";
import store from "@/store";
import axios from "@axios";

export default {
    components: {
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        vSelect,
        BFormSelect,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    model: {
        prop: "isAddNewUserSidebarActive",
        event: "update:is-add-new-user-sidebar-active",
    },
    props: {
        isAddNewUserSidebarActive: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            required,
            alphaNum,
            email,
            countries,
        };
    },
    setup(props, { emit }) {
        const blankUserData = {
            name: "",
            email: "",
            password: "",
            c_password: "",
            roles: [],
        };

        const userData = ref(JSON.parse(JSON.stringify(blankUserData)));
        const resetuserData = () => {
            userData.value = JSON.parse(JSON.stringify(blankUserData));
        };

        const onSubmit = () => {
            store.dispatch("app-user/addUser", userData.value).then(() => {
                emit("refetch-data");
                emit("update:is-add-new-user-sidebar-active", false);
            });
        };

        const { refFormObserver, getValidationState, resetForm } =
            formValidation(resetuserData);

        return {
            userData,
            onSubmit,

            refFormObserver,
            getValidationState,
            resetForm,
        };
    },
    data() {
        return {
            roles: [],
        };
    },
    mounted() {
        this.getRoles();
    },
    methods: {
        getRoles() {
            axios
                .get("/api/dragon/roles")
                .then((response) => {
                    let data = response.data.data;
                    //convert data array to value text keys
                    data = data.map(function (obj) {
                        return { value: obj.Codigo, text: obj.Nombre };
                    });
                    this.roles = data;
                    //this.roles = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
    .vs__dropdown-menu {
        max-height: 200px !important;
    }
}
</style>
